import axios from "axios";
import { useEffect, useState } from "react";
import MainLayout from "../../Layout/MainLayout";
import BASEURL from "../../Config/global";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import CustomAudioCard from "../../Components/CustomAudioCard";
import AudioStrip from "../../Components/AudioStrip";
import { userLogout } from "../../Store/Slices/APIs";
import "./style.css";
import Common from "../../Util/common";

const FeaturedMixes = () => {

  const dispatch = useDispatch();
  const [categorizedSoundsData, setCategorizedSoundsData] = useState([]);

  useEffect(() => {
    dispatch(userLogout())
  }, [])

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // http://backend.dev.relax.scofa.com/api/sounds/mixture-categories/'
      const response = await axios.get(`${BASEURL}/api/sounds/mixture-categories/`);
      if (!response?.data?.error && response?.data?.data?.length > 0) {
        const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
        console.log(sortedData)
        setCategorizedSoundsData(sortedData);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <MainLayout title="Featured Mixes">
        <div className="row mb-3">
          <div className="col-12">
            <Accordion
              defaultActiveKey={["0"]}
              alwaysOpen
              className="customAccordion audio-accordian"
            >
              {categorizedSoundsData && categorizedSoundsData?.map((categorizedData, index) => {
                let sortedChildrens = categorizedData.children_category_data
                if (sortedChildrens > 0) {
                  sortedChildrens = sortedChildrens.sort(function (a, b) { return a.sequence - b.sequence })
                }
                return (
                  <Accordion.Item eventKey={index == 0 ? '0' : Math.random()} key={categorizedData.id}>
                    <Accordion.Header
                      style={{
                        backgroundImage: `url(${categorizedData?.banner})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundColor: '#1c3664',
                      }}
                    >
                      {categorizedData?.name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={index == 0 ? 'audio-boxes-wrapper' : 'audios-rows audioStrips stripedRows'}>
                        {categorizedData?.category_data?.length && categorizedData?.category_data?.map((item, idx) => (
                          index == 0 ? <CustomAudioCard key={idx} item={item} type={Common.FEATUREDMIXES} /> : <AudioStrip key={index} item={item} type={Common.FEATUREDMIXES} />
                        ))}
                      </div>
                      <div className="">
                        {sortedChildrens?.length && sortedChildrens?.map((categorizedDataiii, index2) => (
                          <Accordion.Item eventKey={Math.random()} key={index2}>
                            <Accordion.Header
                              className="sub-category-header"
                              style={{
                                backgroundImage: `url(${categorizedDataiii?.banner})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundColor: '#1c3664',
                              }}
                            >
                              {categorizedDataiii?.name}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="audios-rows audioStrips stripedRows">
                                {categorizedDataiii?.category_data?.length && categorizedDataiii?.category_data?.map((item, idx) => (
                                  <AudioStrip key={idx} item={item} type={Common.FEATUREDMIXES} />
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default FeaturedMixes;


const data = [
  {
    "name": "featured",
    "banner": "https://storage.googleapis.com/relax_dev/assets/avif_test_2.jpg",
    "category_data": [
      {
        "id": 127,
        "category_name": null,
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": null,
          "child_subcategory_name": null
        },
        "category_id": null,
        "name": "Save Mix Latest",
        "position": 4,
        "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
        "featured": false,
        "updated_at": "2024-11-19T09:59:37.853103-05:00",
        "featured_mix_status": true,
        "title": null,
        "description": null,
        "user": 4099,
        "sounds": [
          {
            "id": 5983,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 127
          },
          {
            "id": 5984,
            "sounds_detail": [
              {
                "id": 258,
                "meditationcategoriesname": "Mindfulness",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 22,
                "title": "Improve Focus",
                "duration": "515.082449",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-27T12:15:17.074525-05:00",
                "naration": false,
                "featured": true,
                "sequence": 1,
                "featured_sequence": 0
              }
            ],
            "name": "Improve Focus",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 258,
            "volume": null,
            "soundmix": 127
          },
          {
            "id": 5985,
            "sounds_detail": [
              {
                "id": 580,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Awakening Soul",
                "duration": null,
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Awakening-Soul.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
                "premium": false,
                "created_datetime": "2023-12-19T16:48:20.665224-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul_Patch_iOS_for_-20_dB_LUFS.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS_Er6YMsA.mp3",
                "sequence": 161,
                "hidden": false
              }
            ],
            "name": "Awakening Soul",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 580,
            "volume": null,
            "soundmix": 127
          }
        ]
      }
    ]
  },
  {
    "id": 43,
    "category_data": [],
    "children_category_data": [
      {
        "id": 44,
        "name": "Test W 11/14",
        "sequence": 44
      }
    ],
    "name": "Test Featured w 11/14",
    "created_at": "2024-11-13T15:45:56.818023-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Test%20Featured%20w%2011/14.png",
    "sequence": 43,
    "parent_category": null
  },
  {
    "id": 1,
    "category_data": [
      {
        "id": 191,
        "category_name": "Parent Category 2",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": 23,
          "child_subcategory_name": "Child Category 1"
        },
        "category_id": 1,
        "name": "name",
        "position": null,
        "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
        "featured": true,
        "updated_at": "2024-11-15T09:32:03.506446-05:00",
        "featured_mix_status": false,
        "title": null,
        "description": null,
        "user": 4106,
        "sounds": [
          {
            "id": 6153,
            "sounds_detail": [
              {
                "id": 569,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Bamboo Fountain",
                "duration": "178.337959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Bamboo-Fountain.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent-_256_2WMrfoY.svg",
                "premium": false,
                "created_datetime": "2023-12-19T15:32:00.244269-05:00",
                "naration": false,
                "featured": true,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2min_mixdown_LUFS_-41.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2_30min_mixdown_LUFS_-41.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_Mp3_mixdown_LUFS_-39.mp3",
                "sequence": 8,
                "hidden": false
              }
            ],
            "name": "Bamboo Fountain",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent-_256_2WMrfoY.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Bamboo-Fountain.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 569,
            "volume": null,
            "soundmix": 191
          },
          {
            "id": 6154,
            "sounds_detail": [
              {
                "id": 252,
                "meditationcategoriesname": "Box Breathing",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 24,
                "title": "Remaining Calm Every Day",
                "duration": "794.305306",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2307241511_Remaining_Calm_Every_Day_-_Box_Breathing_for_Beginners_Final_-14_LUFS.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Remaining-Calm-Every-day.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Remaining-Calm-Every-day_tbnRxLR.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-21T17:53:03.537774-05:00",
                "naration": false,
                "featured": false,
                "sequence": 5,
                "featured_sequence": 0
              }
            ],
            "name": "Remaining Calm Every Day",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Remaining-Calm-Every-day_tbnRxLR.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Remaining-Calm-Every-day.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2307241511_Remaining_Calm_Every_Day_-_Box_Breathing_for_Beginners_Final_-14_LUFS.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 252,
            "volume": null,
            "soundmix": 191
          }
        ]
      }
    ],
    "children_category_data": [
      {
        "id": 23,
        "name": "Child Category 1",
        "sequence": 23
      },
      {
        "id": 24,
        "name": "Child Category 2",
        "sequence": 24
      }
    ],
    "name": "Parent Category 2",
    "created_at": "2024-11-05T12:01:21.841197-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/avif_test_2.jpg",
    "sequence": 1,
    "parent_category": null
  },
  {
    "id": 4,
    "category_data": [
      {
        "id": 128,
        "category_name": "Saud Test Category",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": 16,
          "child_subcategory_name": "Child 0023"
        },
        "category_id": 4,
        "name": "Save Mix Latest 2",
        "position": 2,
        "banner": "https://storage.googleapis.com/relax_dev/assets/Save_Mix_Latest_2.png",
        "featured": true,
        "updated_at": "2024-11-19T09:59:37.859191-05:00",
        "featured_mix_status": false,
        "title": null,
        "description": null,
        "user": 4099,
        "sounds": [
          {
            "id": 5986,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 128
          },
          {
            "id": 5987,
            "sounds_detail": [
              {
                "id": 569,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Bamboo Fountain",
                "duration": "178.337959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Bamboo-Fountain.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent-_256_2WMrfoY.svg",
                "premium": false,
                "created_datetime": "2023-12-19T15:32:00.244269-05:00",
                "naration": false,
                "featured": true,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2min_mixdown_LUFS_-41.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2_30min_mixdown_LUFS_-41.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_Mp3_mixdown_LUFS_-39.mp3",
                "sequence": 8,
                "hidden": false
              }
            ],
            "name": "Bamboo Fountain",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent-_256_2WMrfoY.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Bamboo-Fountain.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 569,
            "volume": null,
            "soundmix": 128
          },
          {
            "id": 5988,
            "sounds_detail": [
              {
                "id": 258,
                "meditationcategoriesname": "Mindfulness",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 22,
                "title": "Improve Focus",
                "duration": "515.082449",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-27T12:15:17.074525-05:00",
                "naration": false,
                "featured": true,
                "sequence": 1,
                "featured_sequence": 0
              }
            ],
            "name": "Improve Focus",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 258,
            "volume": null,
            "soundmix": 128
          },
          {
            "id": 5989,
            "sounds_detail": [
              {
                "id": 580,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Awakening Soul",
                "duration": null,
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Awakening-Soul.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
                "premium": false,
                "created_datetime": "2023-12-19T16:48:20.665224-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul_Patch_iOS_for_-20_dB_LUFS.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS_Er6YMsA.mp3",
                "sequence": 161,
                "hidden": false
              }
            ],
            "name": "Awakening Soul",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 580,
            "volume": null,
            "soundmix": 128
          }
        ]
      },
      {
        "id": 126,
        "category_name": "Saud Test Category",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": 15,
          "child_subcategory_name": "Child 001"
        },
        "category_id": 4,
        "name": "test featured",
        "position": 6,
        "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
        "featured": true,
        "updated_at": "2024-11-18T08:10:00.345623-05:00",
        "featured_mix_status": false,
        "title": null,
        "description": null,
        "user": 4099,
        "sounds": [
          {
            "id": 5980,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 126
          },
          {
            "id": 5981,
            "sounds_detail": [
              {
                "id": 256,
                "meditationcategoriesname": "Box Breathing",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 24,
                "title": "Manage Anxiety, Stress and Overthinking",
                "duration": "452.257959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2307201454_FINAL_Manage_Anxiety_Stress_and_Overthinking__-_Storma_S._VO_V2_LUFS_-14.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking_TxqUxzh.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-21T18:03:21.566180-05:00",
                "naration": false,
                "featured": false,
                "sequence": 4,
                "featured_sequence": 0
              }
            ],
            "name": "Manage Anxiety, Stress and Overthinking",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking_TxqUxzh.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2307201454_FINAL_Manage_Anxiety_Stress_and_Overthinking__-_Storma_S._VO_V2_LUFS_-14.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 256,
            "volume": null,
            "soundmix": 126
          },
          {
            "id": 5982,
            "sounds_detail": [
              {
                "id": 580,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Awakening Soul",
                "duration": null,
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Awakening-Soul.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
                "premium": false,
                "created_datetime": "2023-12-19T16:48:20.665224-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul_Patch_iOS_for_-20_dB_LUFS.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS_Er6YMsA.mp3",
                "sequence": 161,
                "hidden": false
              }
            ],
            "name": "Awakening Soul",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 580,
            "volume": null,
            "soundmix": 126
          }
        ]
      }
    ],
    "children_category_data": [
      {
        "id": 15,
        "name": "Child 001",
        "sequence": 15
      },
      {
        "id": 16,
        "name": "Child 0023",
        "sequence": 16
      }
    ],
    "name": "Saud Test Category",
    "created_at": "2024-11-06T15:47:56.965758-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Saud_Test_Category.png",
    "sequence": 4,
    "parent_category": null
  },
  {
    "id": 25,
    "category_data": [],
    "children_category_data": [],
    "name": "My Category 123",
    "created_at": "2024-11-08T08:50:56.517031-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/My_Category.png",
    "sequence": 25,
    "parent_category": null
  },
  {
    "id": 26,
    "category_data": [
      {
        "id": 125,
        "category_name": "Latest Category Before Demo",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": 27,
          "child_subcategory_name": "Test Child 01"
        },
        "category_id": 26,
        "name": "save mix",
        "position": 1,
        "banner": "https://storage.googleapis.com/relax_dev/assets/test_M04n4Fy.png",
        "featured": true,
        "updated_at": "2024-11-19T09:59:37.846898-05:00",
        "featured_mix_status": false,
        "title": "test",
        "description": "test",
        "user": 4099,
        "sounds": [
          {
            "id": 5977,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 125
          },
          {
            "id": 5978,
            "sounds_detail": [
              {
                "id": 258,
                "meditationcategoriesname": "Mindfulness",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 22,
                "title": "Improve Focus",
                "duration": "515.082449",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-27T12:15:17.074525-05:00",
                "naration": false,
                "featured": true,
                "sequence": 1,
                "featured_sequence": 0
              }
            ],
            "name": "Improve Focus",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 258,
            "volume": null,
            "soundmix": 125
          },
          {
            "id": 5979,
            "sounds_detail": [
              {
                "id": 580,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Awakening Soul",
                "duration": null,
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Awakening-Soul.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
                "premium": false,
                "created_datetime": "2023-12-19T16:48:20.665224-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul_Patch_iOS_for_-20_dB_LUFS.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS_Er6YMsA.mp3",
                "sequence": 161,
                "hidden": false
              }
            ],
            "name": "Awakening Soul",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 580,
            "volume": null,
            "soundmix": 125
          }
        ]
      }
    ],
    "children_category_data": [
      {
        "id": 27,
        "name": "Test Child 01",
        "sequence": 27
      },
      {
        "id": 28,
        "name": "Test Child 02",
        "sequence": 28
      }
    ],
    "name": "Latest Category Before Demo",
    "created_at": "2024-11-08T12:08:08.827573-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Latest_Category.png",
    "sequence": 26,
    "parent_category": null
  },
  {
    "id": 45,
    "category_data": [],
    "children_category_data": [
      {
        "id": 46,
        "name": "Test 123 W Again",
        "sequence": 46
      },
      {
        "id": 47,
        "name": "test second",
        "sequence": 47
      }
    ],
    "name": "Test123 w",
    "created_at": "2024-11-13T15:50:46.328979-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Test123_w.png",
    "sequence": 45,
    "parent_category": null
  },
  {
    "id": 29,
    "category_data": [
      {
        "id": 129,
        "category_name": "Add Parent Featured Category",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": 30,
          "child_subcategory_name": "Child 001"
        },
        "category_id": 29,
        "name": "Save Mix Latest 3",
        "position": 7,
        "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
        "featured": true,
        "updated_at": "2024-11-18T08:10:00.352033-05:00",
        "featured_mix_status": false,
        "title": null,
        "description": null,
        "user": 4099,
        "sounds": [
          {
            "id": 5990,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 129
          },
          {
            "id": 5991,
            "sounds_detail": [
              {
                "id": 569,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Bamboo Fountain",
                "duration": "178.337959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Bamboo-Fountain.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent-_256_2WMrfoY.svg",
                "premium": false,
                "created_datetime": "2023-12-19T15:32:00.244269-05:00",
                "naration": false,
                "featured": true,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2min_mixdown_LUFS_-41.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2_30min_mixdown_LUFS_-41.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_Mp3_mixdown_LUFS_-39.mp3",
                "sequence": 8,
                "hidden": false
              }
            ],
            "name": "Bamboo Fountain",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent-_256_2WMrfoY.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Bamboo-Fountain.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 569,
            "volume": null,
            "soundmix": 129
          },
          {
            "id": 5992,
            "sounds_detail": [
              {
                "id": 528,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Waterfall",
                "duration": "188.891429",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_OGG_LUFS_-35.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Water-Fall.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_CZj5ZjA.svg",
                "premium": false,
                "created_datetime": "2023-12-19T10:53:23.806150-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_2_min_LUFS_-37.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_2_30_LUFS_-37.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_Mp3_LUFS_-35.mp3",
                "sequence": 11,
                "hidden": false
              }
            ],
            "name": "Waterfall",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_CZj5ZjA.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Water-Fall.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Water_Fall_-_OGG_LUFS_-35.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 528,
            "volume": null,
            "soundmix": 129
          },
          {
            "id": 5993,
            "sounds_detail": [
              {
                "id": 258,
                "meditationcategoriesname": "Mindfulness",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 22,
                "title": "Improve Focus",
                "duration": "515.082449",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-27T12:15:17.074525-05:00",
                "naration": false,
                "featured": true,
                "sequence": 1,
                "featured_sequence": 0
              }
            ],
            "name": "Improve Focus",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 258,
            "volume": null,
            "soundmix": 129
          },
          {
            "id": 5994,
            "sounds_detail": [
              {
                "id": 580,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Awakening Soul",
                "duration": null,
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Awakening-Soul.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
                "premium": false,
                "created_datetime": "2023-12-19T16:48:20.665224-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul_Patch_iOS_for_-20_dB_LUFS.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS_Er6YMsA.mp3",
                "sequence": 161,
                "hidden": false
              }
            ],
            "name": "Awakening Soul",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 580,
            "volume": null,
            "soundmix": 129
          }
        ]
      }
    ],
    "children_category_data": [
      {
        "id": 30,
        "name": "Child 001",
        "sequence": 30
      },
      {
        "id": 31,
        "name": "Child 002",
        "sequence": 31
      }
    ],
    "name": "Add Parent Featured Category",
    "created_at": "2024-11-12T09:07:09.584927-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Add_Parent_Featured_Category.png",
    "sequence": 29,
    "parent_category": null
  },
  {
    "id": 33,
    "category_data": [
      {
        "id": 133,
        "category_name": "Testers",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": null,
          "child_subcategory_name": null
        },
        "category_id": 33,
        "name": "Testing 1",
        "position": 2,
        "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
        "featured": false,
        "updated_at": "2024-11-12T16:08:55.020950-05:00",
        "featured_mix_status": false,
        "title": null,
        "description": null,
        "user": 4113,
        "sounds": [
          {
            "id": 6009,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 133
          },
          {
            "id": 6010,
            "sounds_detail": [
              {
                "id": 528,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Waterfall",
                "duration": "188.891429",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_OGG_LUFS_-35.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Water-Fall.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_CZj5ZjA.svg",
                "premium": false,
                "created_datetime": "2023-12-19T10:53:23.806150-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_2_min_LUFS_-37.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_2_30_LUFS_-37.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_Mp3_LUFS_-35.mp3",
                "sequence": 11,
                "hidden": false
              }
            ],
            "name": "Waterfall",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_CZj5ZjA.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Water-Fall.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Water_Fall_-_OGG_LUFS_-35.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 528,
            "volume": null,
            "soundmix": 133
          },
          {
            "id": 6011,
            "sounds_detail": [
              {
                "id": 256,
                "meditationcategoriesname": "Box Breathing",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 24,
                "title": "Manage Anxiety, Stress and Overthinking",
                "duration": "452.257959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2307201454_FINAL_Manage_Anxiety_Stress_and_Overthinking__-_Storma_S._VO_V2_LUFS_-14.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking_TxqUxzh.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-21T18:03:21.566180-05:00",
                "naration": false,
                "featured": false,
                "sequence": 4,
                "featured_sequence": 0
              }
            ],
            "name": "Manage Anxiety, Stress and Overthinking",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking_TxqUxzh.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2307201454_FINAL_Manage_Anxiety_Stress_and_Overthinking__-_Storma_S._VO_V2_LUFS_-14.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 256,
            "volume": null,
            "soundmix": 133
          },
          {
            "id": 6012,
            "sounds_detail": [
              {
                "id": 628,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Sunrise Lounge",
                "duration": "354.024",
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Sunrise-Lounge.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_umpIxqe.svg",
                "premium": false,
                "created_datetime": "2024-02-19T15:14:05.227377-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Silent_Track_WjTghzN.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS_E5sfcqu.mp3",
                "sequence": 10,
                "hidden": false
              }
            ],
            "name": "Sunrise Lounge",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_umpIxqe.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Sunrise-Lounge.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 628,
            "volume": null,
            "soundmix": 133
          }
        ]
      },
      {
        "id": 130,
        "category_name": "Testers",
        "type": "mixture",
        "subcategory_obj": {
          "child_subcategory_id": null,
          "child_subcategory_name": null
        },
        "category_id": 33,
        "name": "tester mix",
        "position": 3,
        "banner": "https://storage.googleapis.com/relax_dev/assets/tester_mix_trPLzbo.png",
        "featured": true,
        "updated_at": "2024-11-18T08:10:00.327623-05:00",
        "featured_mix_status": false,
        "title": null,
        "description": null,
        "user": 4099,
        "sounds": [
          {
            "id": 5995,
            "sounds_detail": [
              {
                "id": 519,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Thunder",
                "duration": "189.518367",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_OGG_-_28_lufs.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Thunder.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
                "premium": false,
                "created_datetime": "2023-12-19T09:32:20.058890-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_min_-_28_lufs.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Thunder_-_Mp3_-_28_lufs.mp3",
                "sequence": 7,
                "hidden": false
              }
            ],
            "name": "Thunder",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 519,
            "volume": null,
            "soundmix": 130
          },
          {
            "id": 5996,
            "sounds_detail": [
              {
                "id": 569,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Bamboo Fountain",
                "duration": "178.337959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Bamboo-Fountain.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent-_256_2WMrfoY.svg",
                "premium": false,
                "created_datetime": "2023-12-19T15:32:00.244269-05:00",
                "naration": false,
                "featured": true,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2min_mixdown_LUFS_-41.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_2_30min_mixdown_LUFS_-41.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Bamboo_Fountain_Mp3_mixdown_LUFS_-39.mp3",
                "sequence": 8,
                "hidden": false
              }
            ],
            "name": "Bamboo Fountain",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent-_256_2WMrfoY.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Bamboo-Fountain.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Bamboo_Fountain_OGG_mixdown_LUFS_-39.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 569,
            "volume": null,
            "soundmix": 130
          },
          {
            "id": 5997,
            "sounds_detail": [
              {
                "id": 528,
                "soundscategoriesname": "Nature",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 165,
                "title": "Waterfall",
                "duration": "188.891429",
                "audio": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_OGG_LUFS_-35.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Water-Fall.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_CZj5ZjA.svg",
                "premium": false,
                "created_datetime": "2023-12-19T10:53:23.806150-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_2_min_LUFS_-37.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_2_30_LUFS_-37.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/Water_Fall_-_Mp3_LUFS_-35.mp3",
                "sequence": 11,
                "hidden": false
              }
            ],
            "name": "Waterfall",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_CZj5ZjA.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Water-Fall.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/Water_Fall_-_OGG_LUFS_-35.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 528,
            "volume": null,
            "soundmix": 130
          },
          {
            "id": 5998,
            "sounds_detail": [
              {
                "id": 256,
                "meditationcategoriesname": "Box Breathing",
                "type": "relaxation",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "meditationcategory": 24,
                "title": "Manage Anxiety, Stress and Overthinking",
                "duration": "452.257959",
                "audio": "https://storage.googleapis.com/relax_dev/assets/2307201454_FINAL_Manage_Anxiety_Stress_and_Overthinking__-_Storma_S._VO_V2_LUFS_-14.mp3",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking_TxqUxzh.png",
                "premium": false,
                "text": "",
                "genre": null,
                "created_datetime": "2023-12-21T18:03:21.566180-05:00",
                "naration": false,
                "featured": false,
                "sequence": 4,
                "featured_sequence": 0
              }
            ],
            "name": "Manage Anxiety, Stress and Overthinking",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking_TxqUxzh.png",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Manage-_Anxiety-Stress-And-Overthinking.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/2307201454_FINAL_Manage_Anxiety_Stress_and_Overthinking__-_Storma_S._VO_V2_LUFS_-14.mp3",
            "type": "relaxation",
            "narrate": false,
            "sound": 256,
            "volume": null,
            "soundmix": 130
          },
          {
            "id": 5999,
            "sounds_detail": [
              {
                "id": 628,
                "soundscategoriesname": "Music",
                "type": "sounds",
                "subcategory_obj": {
                  "child_subcategory_id": null,
                  "child_subcategory_name": null
                },
                "soundcategory": 164,
                "title": "Sunrise Lounge",
                "duration": "354.024",
                "audio": "https://storage.googleapis.com/relax_dev/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS.ogg",
                "image": "https://storage.googleapis.com/relax_dev/assets/Relax-Scofa-Sunrise-Lounge.png",
                "banner": "https://storage.googleapis.com/relax_dev/assets/no_image.png",
                "thumbnail": "https://storage.googleapis.com/relax_dev/assets/Transparent_-_WithoutCircle-_256_umpIxqe.svg",
                "premium": false,
                "created_datetime": "2024-02-19T15:14:05.227377-05:00",
                "naration": false,
                "featured": false,
                "ios_sound": "https://storage.googleapis.com/relax_dev/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS.mp3",
                "ios_patch": "https://storage.googleapis.com/relax_dev/assets/Silent_Track_WjTghzN.mp3",
                "audio_mobile": "https://storage.googleapis.com/relax_dev/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS_E5sfcqu.mp3",
                "sequence": 10,
                "hidden": false
              }
            ],
            "name": "Sunrise Lounge",
            "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_umpIxqe.svg",
            "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Sunrise-Lounge.png",
            "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0017_-_Sunrise_Lounge_-20_dB_LUFS.ogg",
            "type": "sounds",
            "narrate": false,
            "sound": 628,
            "volume": null,
            "soundmix": 130
          }
        ]
      }
    ],
    "children_category_data": [],
    "name": "Testers",
    "created_at": "2024-11-12T09:22:05.581868-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Testers_SUo8Qjc.png",
    "sequence": 33,
    "parent_category": null
  },
  {
    "id": 48,
    "category_data": [],
    "children_category_data": [
      {
        "id": 49,
        "name": "Test waqar sub",
        "sequence": 49
      }
    ],
    "name": "test Waqar",
    "created_at": "2024-11-13T15:57:49.378175-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/test_Waqar.png",
    "sequence": 48,
    "parent_category": null
  },
  {
    "id": 37,
    "category_data": [],
    "children_category_data": [],
    "name": "Test",
    "created_at": "2024-11-13T08:50:57.239284-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Test_GMQFYa5.png",
    "sequence": 37,
    "parent_category": null
  },
  {
    "id": 50,
    "category_data": [],
    "children_category_data": [
      {
        "id": 51,
        "name": "Testing 11/14/24 02",
        "sequence": 51
      }
    ],
    "name": "Testing 11/14/24",
    "created_at": "2024-11-13T16:09:25.951046-05:00",
    "banner": "https://storage.googleapis.com/relax_dev/assets/Testing%2011/14/24.png",
    "sequence": 50,
    "parent_category": null
  }
]