import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { playAudio, playMyMixer, playMyMixerObj } from "../../Store/Slices/SoundPlayerSlice";

import axios from "axios";

import BASEURL from "../../Config/global";
import MainLayout from "../../Layout/MainLayout";

import { BackButton, BackButtonNew, OutlinePause, OutlinePlay, PauseButton, PlayButton } from "../../Assets/svg";
import "./style.css";
import { pauseMixer, playMixer } from "../../Store/Slices/MixerSlice";
import VolumeBar from "../../Components/VolumeBar";
import ReactAudioPlayer from "react-audio-player";
import { AudioDurationClose, AudioDurationCurrentTime, AudioDurationStop, AudioSeek } from "../../Store/Slices/AudioSlice";
import { Link } from "react-router-dom";
import AudioStrip from "../../Components/AudioStrip";
import { isIOS, isSafari } from "react-device-detect";
import { Howl, Howler } from "howler";
import { userLogout } from "../../Store/Slices/APIs";
// import { FillPlay, FillPause, TrackedPlay } from '../Assets/svg';
import CryptoJS from 'crypto-js';
import { ClearExternalMixData, handleMixerTray } from "../../Store/Slices/MyMixerSlice";

const FeaturedMixesDetail = () => {

    const dispatch = useDispatch();

    // Get ID from URL
    const { id } = useParams();

    // Get Data From Redux
    const mix = useSelector((state) => state.soundPlayer.mixObj);
    const isPlaying = useSelector((state) => state.mixer.play);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set Use Sate 
    const [isPlayingState, setIsPlayingState] = useState(false)
    const [isActiveAudio, setIsActiveAudio] = useState(false)
    const [featuredMixData, setFeaturedMixData] = useState({})

    // Sample Mix Data
    // const [featuredMixData, setFeaturedMixData] = useState({
    //     "id": 179,
    //     "sounds_list": [
    //         {
    //             "id": 5977,
    //             "sounds_detail": [
    //                 {
    //                     "id": 519,
    //                     "soundscategoriesname": "Nature",
    //                     "type": "sounds",
    //                     "subcategory_obj": {
    //                         "child_subcategory_id": null,
    //                         "child_subcategory_name": null
    //                     },
    //                     "soundcategory": 165,
    //                     "title": "Thunder",
    //                     "duration": "189.518367",
    //                     "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
    //                     "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
    //                     "banner": "https://storage.googleapis.com/relax_prod/assets/no_image.png",
    //                     "thumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
    //                     "premium": false,
    //                     "created_datetime": "2023-12-19T09:32:20.058890-05:00",
    //                     "naration": false,
    //                     "featured": false,
    //                     "ios_sound": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_2_min_-_28_lufs.mp3",
    //                     "ios_patch": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_2_30_Min_-_28_lufs.mp3",
    //                     "audio_mobile": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_Mp3_-_28_lufs.mp3",
    //                     "sequence": 7,
    //                     "hidden": false
    //                 }
    //             ],
    //             "name": "Thunder",
    //             "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_znvLqP9.svg",
    //             "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Thunder.png",
    //             "audio": "https://storage.googleapis.com/relax_prod/assets/Thunder_-_OGG_-_28_lufs.ogg",
    //             "type": "sounds",
    //             "narrate": false,
    //             "sound": 519,
    //             "volume": null,
    //             "soundmix": 125
    //         },
    //         {
    //             "id": 5978,
    //             "sounds_detail": [
    //                 {
    //                     "id": 258,
    //                     "meditationcategoriesname": "Mindfulness",
    //                     "type": "relaxation",
    //                     "subcategory_obj": {
    //                         "child_subcategory_id": null,
    //                         "child_subcategory_name": null
    //                     },
    //                     "meditationcategory": 22,
    //                     "title": "Improve Focus",
    //                     "duration": "515.082449",
    //                     "audio": "https://storage.googleapis.com/relax_prod/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
    //                     "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
    //                     "banner": "https://storage.googleapis.com/relax_prod/assets/no_image.png",
    //                     "thumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
    //                     "premium": false,
    //                     "text": "",
    //                     "genre": null,
    //                     "created_datetime": "2023-12-27T12:15:17.074525-05:00",
    //                     "naration": false,
    //                     "featured": true,
    //                     "sequence": 1,
    //                     "featured_sequence": 0
    //                 }
    //             ],
    //             "name": "Improve Focus",
    //             "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_dirwyhm.png",
    //             "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Improve-Focus_kvyViGD.png",
    //             "audio": "https://storage.googleapis.com/relax_prod/assets/2306201101_-_Improve_Focus_mixdown_edit_FINAL_2__-14_LUFS.mp3",
    //             "type": "relaxation",
    //             "narrate": false,
    //             "sound": 258,
    //             "volume": null,
    //             "soundmix": 125
    //         },
    //         {
    //             "id": 5979,
    //             "sounds_detail": [
    //                 {
    //                     "id": 580,
    //                     "soundscategoriesname": "Music",
    //                     "type": "sounds",
    //                     "subcategory_obj": {
    //                         "child_subcategory_id": null,
    //                         "child_subcategory_name": null
    //                     },
    //                     "soundcategory": 164,
    //                     "title": "Awakening Soul",
    //                     "duration": null,
    //                     "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
    //                     "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
    //                     "banner": "https://storage.googleapis.com/relax_prod/assets/no_image.png",
    //                     "thumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
    //                     "premium": false,
    //                     "created_datetime": "2023-12-19T16:48:20.665224-05:00",
    //                     "naration": false,
    //                     "featured": false,
    //                     "ios_sound": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS.mp3",
    //                     "ios_patch": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul_Patch_iOS_for_-20_dB_LUFS.mp3",
    //                     "audio_mobile": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__at_-20_dB_LUFS_Er6YMsA.mp3",
    //                     "sequence": 161,
    //                     "hidden": false
    //                 }
    //             ],
    //             "name": "Awakening Soul",
    //             "tumbnail": "https://storage.googleapis.com/relax_prod/assets/Transparent_-_WithoutCircle-_256_Ifq5WkT.svg",
    //             "image": "https://storage.googleapis.com/relax_prod/assets/Relax-Scofa-Awakening-Soul.png",
    //             "audio": "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg",
    //             "type": "sounds",
    //             "narrate": false,
    //             "sound": 580,
    //             "volume": null,
    //             "soundmix": 125
    //         }
    //     ],
    //     "mix_featured_category_name": "Latest Category Before Demo",
    //     "mix_featured_category_banner": "https://storage.googleapis.com/relax_prod/assets/Latest_Category.png",
    //     "name": "save mix",
    //     "position": 1,
    //     "banner": "https://storage.googleapis.com/relax_prod/assets/avif_test_2.jpg",
    //     "featured": true,
    //     "updated_at": "2024-11-19T09:06:29.074020-05:00",
    //     "featured_mix_status": false,
    //     "title": "Winter Whispers",
    //     "description": "test",
    //     "user": 4099,
    //     "featured_category": 26,
    //     "sub_category": 27
    // })

    useEffect(() => {
        dispatch(userLogout())
    }, [])

    useEffect(() => {
        if (id) {
            getDataByApi(id);
        }
    }, [id])

    useEffect(() => {
        const isActive = mix?.id === featuredMixData?.id;
        setIsActiveAudio(isActive);
        setIsPlayingState(isActive && isPlaying);
    }, [mix, featuredMixData])

    useEffect(() => {
        setIsPlayingState(isPlaying);
      }, [isPlaying])

    const getDataByApi = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/sounds/mixture-categories/${id}`);
            console.log(response)
            setLoading(false);
            if (response.data.error != true) {
                setFeaturedMixData(response.data.data?.[0]);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handlePlay = () => {
        if (isActiveAudio) {
            if (isPlaying) {
                dispatch(pauseMixer());
                setIsPlayingState(false);
            } else {
                dispatch(playMixer());
                setIsPlayingState(true);
            }
        } else {
            dispatch(handleMixerTray(true));
            dispatch(ClearExternalMixData());
            dispatch(playMyMixer(featuredMixData?.sounds_list));
            dispatch(playMyMixerObj(featuredMixData));
            setTimeout(() => {
                dispatch(playMixer());
            }, 500);
        }
    };

    return (
        <MainLayout>
            <div className="go-back rotate">
                <Link to={`/featured-mixes`}><img src={BackButtonNew} className="mw-100 w-22" /> </Link>
            </div>
            <div className="audio-detail-page mt-3 mt-sm-4">
                <div className="row">
                    <div className="col-lg-12 m-auto">
                        {
                            !loading && (
                                <div className="box-wrapper">
                                    <div className={`image-thumbnail ${isImageLoading ? "d-none" : ""}`}>
                                        <img
                                            src={featuredMixData?.banner}
                                            className="img-fluid w-100 rounded-4"
                                            onLoad={() => setIsImageLoading(false)}
                                        />
                                    </div>
                                    <div className="content text-center px-3">
                                        <h6 className="text-white text-capitalize mt-4 pt-2" >Featured Mixes</h6>
                                        <h3 className="text-white my-3 my-sm-4 px-4">{featuredMixData.title}</h3>
                                        {/* <div className=''>
                                            <div className='external-mixer-pallete'>
                                                {
                                                    featuredMixData && featuredMixData['sounds_list'].map((sound, index) => (
                                                        <div className="mixerSoundDetail py-2" key={index}>
                                                            <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                                                <div className="mixerSoundThumbnail mixerStyle">
                                                                    <img
                                                                        src={sound.image}
                                                                        alt="Thumbnail"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mixerAudioTitle text-start m-0">
                                                                    {sound.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> */}
                                        <div className="ppButton mt-3 mt-sm-0 pt-sm-2">
                                            <button
                                                type="button"
                                                className="no-button"
                                                onClick={handlePlay}
                                            >
                                                {isPlayingState && isActiveAudio ? (
                                                    <img src={PauseButton} />
                                                ) : (
                                                    <img src={PlayButton} />
                                                )}
                                            </button>
                                        </div>
                                        <p className="text-white mb-3 mt-4 fs-6" style={{ lineHeight: '1.7', wordBreak: 'break-word' }}>{featuredMixData.description}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default FeaturedMixesDetail;